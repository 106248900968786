/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql } from "gatsby";
import { disableBodyScroll } from "body-scroll-lock";
import { AppContext } from "~context/AppContext";
import Layout from "~components/Layout";
import PersonaHoverGrid from "~components/PersonaHoverGrid";
import SEO from "~components/SEO";

class PersonasPageComponent extends Component {
  componentDidMount() {
    const { appContext } = this.props;

    appContext.setActivePath(`/personas`);
    appContext.setShareUrl(`https://www.myfoodie.id/personas`);
  }

  //

  share = () => {
    if (this.props.appContext.device === `desktop` || !window.navigator.share) {
      disableBodyScroll();
      this.props.appContext.setShareMenuActive(true);

      return;
    }

    const title = `MYFOODIE.ID`;

    window.navigator.share({
      title,
      url: this.props.appContext.shareUrl
    });
  };

  //

  render() {
    const { frontmatter, personas } = this.props;

    return (
      <>
        <SEO
          frontmatterTitle={frontmatter.title}
          frontmatterDescription={frontmatter.seoDescription}
          frontmatterKeywords={frontmatter.seoKeywords}
        />

        <Layout
          className="personas-page w-full h-screen relative bg-purple"
          outerClassName="h-full"
          footerClass="white"
          headerClass="white"
        >
          <section className="w-full h-full relative">
            <PersonaHoverGrid personas={personas} />
          </section>
        </Layout>
      </>
    );
  }
}

const PersonasPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const personas = [];

  data.allMarkdownRemark.edges.forEach(edge => {
    const persona = edge.node.frontmatter;

    persona.url = edge.node.fields.slug;

    personas.push(persona);
  });

  return (
    <AppContext.Consumer>
      {appContext => (
        <PersonasPageComponent
          appContext={appContext}
          frontmatter={frontmatter}
          personas={personas}
        />
      )}
    </AppContext.Consumer>
  );
};

export default PersonasPage;

export const personasPageQuery = graphql`
  query PersonasPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "persona-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tagline
            description
            dinnerDate
            dinnerDateText
            colorClasses
            videoPoster {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
